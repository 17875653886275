<template>
  <div class="section-main-100">
    <CmsPageConstructor
      v-if="pageData"
      :page-url="url"
      :cms-data="pageData"
    />
    <v-progress-linear
      v-else
      height="2"
      :indeterminate="true"
    ></v-progress-linear>
  </div>
</template>

<script>
import CmsPageConstructor from '@/components/views/Sale/CmsPageConstructor'

export default {
  name: 'DefaultPage',
  components: { CmsPageConstructor },
  data () {
    return {
      url: this.$route.path,
      pageData: null
    }
  },
  computed: {
    isLoadingSeoPages () {
      const list = this.$store.getters['serviceData/optionsList'](null)
      return !list
    }
  },
  watch: {
    isLoadingSeoPages: {
      handler (val) {
        if (!val) {
          this.pageData = this.$store.getters['serviceData/optionsList']('cmsPage.pages').find(page => page.url === this.url)
          if (!this.pageData) {
            this.$router.replace({ name: 'notFound' })
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
  @media (max-width: 800px) and (min-width: 200px) {
    .title-h1 span {
      display: block;
      line-height: 1;
    }
  }
</style>
