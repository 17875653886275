<template>
  <div
    class="sale-row"
    @scroll.passive="handleScroll"
  >
    <v-progress-linear
      height="2"
      :indeterminate="isLoading"
      v-if="isLoading"
    ></v-progress-linear>
    <v-container
      fluid
      style="position: relative"
    >
      <ControlPanel
        v-if="needControlPanel && !isLoading"
        :is-loading="isLoading"
        @md-input="setMdValue($event)"
        :sort="sort"
        @update:sort="$emit('update:sort', $event)"
        :pagination="pagination"
        @update:pagination="$emit('update:pagination', $event)"
        :filter="filter"
        @update:filter="$emit('update:filter', $event)"
      />
      <div v-if="!isLoading">
        <AdvertItems
          v-if="isResultExist"
          :items="visibleItems"
          :md="md"
        />
        <NoResult v-else/>
      </div>
      <Pagination
        :value="pagination"
        @input="updatePagination"
        :total-count="items.count"
        v-if="!isLoading"
      />
    </v-container>
  </div>
</template>

<script>
import ControlPanel from './List/ControlPanel'
import AdvertItems from './List/AdvertItems'
import Pagination from './List/Pagination'
import NoResult from '@/components/views/Sale/Common/NoResult'

export default {
  name: 'List',
  components: { NoResult, Pagination, AdvertItems, ControlPanel },
  props: {
    needControlPanel: Boolean,
    isLoading: Boolean,
    items: Object,
    pagination: Object,
    sort: Object,
    filter: Object
  },
  data () {
    return {
      md: 3,
      show: false,
      visibleItemCount: 15, // Начальное количество видимых элементов
      hashElementId: null // Для сохранения ID хэша
    }
  },
  computed: {
    isResultExist () {
      return this.items && this.items.hasOwnProperty('models') && this.items.models.length
    },
    visibleItems () {
      return this.items.models.slice(0, this.visibleItemCount)
    }
  },
  methods: {
    updatePagination (event) {
      this.$emit('update:pagination', event)
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    },
    setMdValue (value) {
      this.md = value
    },
    handleScroll () {
      const bottomOfWindow = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight - 213
      if (bottomOfWindow && this.visibleItemCount < this.items.models.length) {
        this.visibleItemCount += 15 // Увеличиваем количество отображаемых элементов
      }
    },
    scrollToElement (hash) {
      if (hash && document.querySelector(hash)) {
        this.hashElementId = hash // Сохраняем ID хэша
        setTimeout(() => {
          this.$vuetify.goTo(hash, { duration: 500, easing: 'easeInOutCubic' })
        }, 10)
      }
    },
    loadItemsUntil (hash) {
      const targetHash = hash.split('?')[0]

      const targetIndex = this.items.models.findIndex(item => `#card${item.id}` === targetHash)

      if (targetIndex !== -1) {
        // Проверяем, нужно ли подгружать больше элементов
        while (this.visibleItemCount < targetIndex + 1) {
          this.visibleItemCount += 15
        }
        // После загрузки всех необходимых элементов, прокручиваем к нужному
        this.scrollToElement(targetHash)
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    isLoading (newValue) {
      // После завершения загрузки проверяем наличие хэша и скроллим к нужному элементу
      if (!newValue && this.hashElementId) {
        this.scrollToElement(this.hashElementId)
      }
    },
    items () {
      // Прокрутка до элемента при загрузке страницы, если есть хэш
      const hash = window.location.hash
      if (hash) {
        this.loadItemsUntil(hash) // Загрузить элементы до нужного и прокрутить
      }
    }
  }
}
</script>

<style scoped>
  .sale-row {
    min-height: 70vh;
    overflow-y: auto;
  }
</style>
