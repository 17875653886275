<template>
  <div class="advert-list mx-auto d-flex">
    <!--todo: отступ слева из-за flex-start -->
    <div class="d-flex flex-wrap row-advert justify-center">
      <div
        class="col-advert-item"
        v-for="item in items"
        :key="item.id"
      >
        <AdvertItem
          :detail="item"
          :id="'card' + item.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AdvertItem from './AdvertItems/AdvertItem'

export default {
  components: { AdvertItem },
  name: 'AdvertItems',
  props: {
    items: Array
  }
}
</script>

<style scoped>
  .row-advert {
    gap: 10px;
    flex-grow: 0;
  }
  .col-advert-item {
    margin: 4px;
    width: 300px;
  }
  @media (max-width: 3024px) and (min-width: 2160px) {
    .row-advert {
      width: 90%;
      justify-content: flex-start !important;
    }
  }
  @media (max-width: 1024px) and (min-width: 450px) {
    .col-advert-item {
      /*width: 49%;*/
    }
    .row-advert {
      justify-content: center !important;
    }
  }
  @media (max-width: 350px) and (min-width: 10px) {
    .col-advert-item {
      width: 200px;
    }
  }
</style>
