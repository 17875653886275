<template>
  <div
    class="adv-header-word-wrap pt-0 pb-0 font-weight-bold subtitle-1 pl-4 pr-4 pt-3 text-uppercase"
    style="line-height: 1.2"
  >
    <div
      style="width: 100%"
      class="d-flex flex-row align-center justify-space-between pb-1"
    >
      <v-card-subtitle class="pb-0 pl-0 text-caption">ID {{ detail.id }}</v-card-subtitle>
      <div class="pr-4 subtitle-2 grey--text align-center pt-2 d-flex align-content-end">
        <v-icon
          size="20"
          color="grey"
        >mdi-image
        </v-icon>
        {{ countImages }}
      </div>
    </div>
    <div>
      {{ formatTitleAdvert }}
    </div>
  </div>
</template>
<script>
import formatters from '@/components/mixins/formatters'

export default {
  mixins: [formatters],
  name: 'AdvertHeader',
  props: {
    detail: Object,
    countImages: Number
  }
}
</script>

<style>
  .adv-header-word-wrap {
    word-break: break-word !important;
    height: 125px;
    position: relative;
    overflow: hidden;
  }
  .adv-header-word-wrap:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(180deg, transparent, rgb(255, 255, 255));
  }
  @media (max-width: 800px) and (min-width: 200px) {
    .adv-header-word-wrap {
      min-height: auto;
    }
  }
</style>
