import image from '@/components/mixins/image'
import { defaultImageUrl } from '@/config'

export default {
  mixins: [image],
  computed: {
    mainImage () {
      let mainBuildingImage = this.detail.building.buildingImages.find(item => item.is_main)
      if (!mainBuildingImage) {
        mainBuildingImage = this.detail.advertImages.find(item => item.is_main)
      }
      if (mainBuildingImage) {
        return this.thumbSrc(mainBuildingImage.image)
      } else {
        return defaultImageUrl
      }
    }
  }
}
