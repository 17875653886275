<template>
  <v-card
    class="mx-auto rounded-xl"
    style="overflow: hidden"
    max-width="430"
    outlined
    height="414"
  >
    <router-link
      :to="{ path }"
      class="d-flex justify-center image-building"
    >
      <v-img
        :src="mainImage"
        max-width="100%"
        height="230"
      />
    </router-link>
    <FavoritesBtn
      class="heart"
      :item="detail"
    />

    <div
      style="height: 40%"
      class="d-flex flex-column justify-space-between"
    >

      <div>
        <router-link
          :to="{ path }"
          tag="a"
        >
          <AdvertHeader
            :detail="detail"
            :count-images="countImages"
          />
        </router-link>
      </div>

      <div>
        <v-card-actions class="flex-wrap pt-0 pb-0">
          <v-card-title class="pt-0 pl-2 pb-0 font-weight-bold"> {{ formatPrice(detail.price * 1000) }} РУБ
          </v-card-title>
        </v-card-actions>
        <div
          style="font-size: 12px;"
          class="pl-4 pb-2 pt-1"
        >актуально на: {{ formatTodayDate(detail.updated_at) }}
        </div>
      </div>
    </div>

  </v-card>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import AdvertHeader from '@/components/views/Sale/Common/AdvertHeader'
import FavoritesBtn from '@/components/views/Sale/Common/FavoritesBtn'
import image from '@/components/mixins/image'
import mainImage from '@/components/mixins/mainImage'

export default {
  mixins: [formatters, image, mainImage],
  name: 'AdvertItem',
  components: { FavoritesBtn, AdvertHeader },
  props: {
    detail: Object
  },
  computed: {
    path () {
      let resPath = this.$router.resolve({ name: this.$route.name + 'Detail' }).resolved.matched[0].path
      return resPath.replace('*', this.$route.path).split(':')[0] + this.detail.id
    },
    countImages () {
      return this.detail.advertImages.length + this.detail.building.buildingImages.length
    }
  }
}
</script>

<style scoped>
  .image-building {
    position: relative;
  }
  .heart {
    position: absolute;
    right: 10px;
    top: 5px;
  }
  a:visited {
    color: #609;
  }
</style>
