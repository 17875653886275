<template>
  <div class="pagination-block">
    <v-row>
      <v-col cols="12">
        <v-pagination
          class="elevation-0"
          :value="value.pageNumber * 1"
          @input="handleInput($event)"
          :length="length"
          next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left"
          :page="value.pageNumber * 1"
          :total-visible="visible"></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    value: Object,
    totalCount: Number
  },
  data: () => ({
    visible: 10,
    perPage: 10
  }),
  computed: {
    length () {
      let length = 1
      if (this.totalCount && this.value) {
        length = Math.ceil(this.totalCount / (this.value.perPage * 1))
      }
      return length
    }
  },
  methods: {
    handleInput (data) {
      this.$emit('input', {
        pageNumber: data,
        perPage: this.value.perPage
      })
    }
  }
}
</script>

<style scoped>
  /deep/ .v-pagination__item, /deep/ .v-pagination__navigation {
    box-shadow: none !important;
    border: 1px solid #c1c1c1;
  }
</style>
