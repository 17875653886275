<template>
  <div>
    <vue-headful
      :title="cmsData.title"
      :description="cmsData.meta_description"
    />
    <v-container
      fluid
      class="section pt-9 pl-9 mb-8"
    >
      <h1
        style="max-width: 40%; line-height: 1.2"
        class="text-h3 font-weight-bold"
      >{{ cmsData.header }}</h1>
      <p
        style="max-width: 70%;"
        class="pt-4"
      >{{ cmsData.content }}</p>
      <Btn
        rounded
        class="colorful-button white--text pa-5"
        title="Другие варианты"
        :loading="isLoading"
        :to="{name: 'sale'}"
        max-width="200"
      />
    </v-container>
    <List
      need-control-panel
      :is-loading="isLoading"
      :items="items"
      @update:filter="handleFilterChange($event)"
      :pagination.sync="query.pagination"
      :sort.sync="query.sort"
      :filter="query.filter"
      id="AdvertItems"
    />
  </div>
</template>

<script>
import List from './List'
import list from '@/components/mixins/list'
import Btn from '@/components/base/Btn'

export default {
  mixins: [list],
  name: 'CmsPageConstructor',
  components: { List, Btn },
  data () {
    return {
      apiUrl: '/sale',
      isOpenSnackbar: true
    }
  },
  watch: {
    isLoading: {
      handler () {
        if (this.getHash && !this.isLoading) {
          setTimeout(() => {
            if (document.querySelector(this.getHash)) {
              this.$vuetify.goTo(this.getHash, { duration: 500, easing: 'easeInOutCubic' })
            }
          }, 10)
        }
      }
    }
  }
}
</script>

<style scoped>
  @media (max-width: 800px) and (min-width: 200px) {
    .title-h1 span {
      display: block;
      line-height: 1;
    }
  }
</style>
