<template>
  <div class="control-panel-block d-flex justify-end mb-4">
    <!--   <div class="viewsMdList-block"-->
    <!--         v-for="item in itemsMdList"-->
    <!--         :key="item.icon">-->
    <!--      <v-btn class="mr-2"-->
    <!--             @click="emitMdValue(item.value)"-->
    <!--             :title="item.title">-->
    <!--        <v-icon>{{item.icon}}</v-icon>-->
    <!--      </v-btn>-->
    <!--    </div>-->
    <div class="d-flex select-adsOnPage pl-2 mr-2 pb-4">
      <p class="text-subtitle-1 text-uppercase text-h6 ma-0 pr-2 align-self-center">Объявлений на странице:</p>
      <v-select
        :value="getItemsPerPage"
        @input="handlePerPage($event)"
        :items="perPageList"
        label="Объявлений на странице"
        class="select-adsOnPage"
        dense
        hide-details
        solo
      ></v-select>
    </div>
    <div class="sort">
      <v-select
        :value="getSortById"
        @input="handleSortSelection($event)"
        :items="sortList"
        item-text="title"
        item-value="id"
        return-object
        label="Сортировка"
        dense
        solo
        hide-details
      ></v-select>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ControlPanel',
  props: {
    sort: Object,
    pagination: Object,
    filter: Object,
    isLoading: Boolean
  },
  data: () => ({
    localId: null,
    sortList: [
      {
        id: 1,
        title: 'Сначала новые',
        property: 'published_at',
        order: 'DESC'
      },
      {
        id: 2,
        title: 'Сначала старые',
        property: 'published_at',
        order: 'ASC'
      },
      {
        id: 3,
        title: 'Сначала дешевые',
        property: 'price',
        order: 'ASC'
      },
      {
        id: 4,
        title: 'Сначала дорогие',
        property: 'price',
        order: 'DESC'
      },
      {
        id: 5,
        title: 'Сначала актуальные цены',
        property: 'updated_at',
        order: 'DESC'
      },
      {
        id: 6,
        title: 'Сначала архивные цены',
        property: 'updated_at',
        order: 'ASC'
      }
    ],
    itemsMdList: [
      {
        title: 'Большие блоки',
        icon: 'mdi-grid-large',
        value: 4
      },
      {
        title: 'Маленькие блоки',
        icon: 'mdi-grid',
        value: 3
      }
    ],
    perPageList: ['20', '100']
  }),
  computed: {
    getSortById () {
      let id = null
      const objKeys = Object.keys(this.sort)
      if (objKeys.length === 1) {
        const sortObject = this.sortList.find(item => item.property === objKeys[0] && item.order === this.sort[objKeys[0]])
        if (sortObject) {
          id = sortObject.id
        }
      }
      return id
    },
    getItemsPerPage () {
      let id = null
      if (this.pagination && this.pagination.perPage && this.perPageList.includes(this.pagination.perPage)) {
        id = this.pagination.perPage
      }
      return id
    }
  },
  methods: {
    emitMdValue (value) {
      this.$emit('md-input', value)
    },
    handleSortSelection (data) {
      const payload = {}
      payload[data.property] = data.order
      this.$emit('update:sort', payload)
    },
    handlePerPage (data) {
      this.$emit('update:pagination', {
        pageNumber: this.pagination.pageNumber,
        perPage: data
      })
    }
  }
}
</script>

<style scoped>
  .control-panel-block {
    padding: 20px 10px 0;
    background-color: white;
  }
  /deep/ .v-input__control {
    border: 1px solid #c1c1c1;
  }
  .sort {
    width: 200px;
  }
  .control-panel-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .control-adsOnPage p {
    font-size: 14px;
    margin-right: 10px;
    margin-top: 10px;
  }
  @media (max-width: 800px) and (min-width: 200px) {
    .select-adsOnPage {
      width: 100%;
      margin-right: 0 !important;
    }
    .sort {
      width: 100%;
    }
    .control-panel-block {
      justify-content: start !important;
    }
  }

</style>
