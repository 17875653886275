<template>
  <v-alert
    border="top"
    color="red lighten-2"
    dark
  >
    По вашему запросу объявлений не найдено.
  </v-alert>
</template>

<script>
export default {
  name: 'NoResult'
}
</script>

<style scoped>

</style>
